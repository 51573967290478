import React from 'react';
import './App.css';
// Placeholder images for features
import featureImageURL1 from './1.webp';
import featureImageURL2 from './2.webp';
import featureImageURL3 from './3.webp';
import logoImage from './logo.png'
import { useState, useEffect } from 'react';

const DeletionRequestModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '40px',
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        textAlign: 'center',
        maxWidth: '600px',
        margin: '0 20px',
      }}>
        <h2 style={{ color: '#333', marginBottom: '20px' }}>Account Deletion Request</h2>
        <p style={{ fontSize: '18px', color: '#555', marginBottom: '30px' }}>
          Are you sure you want to request the deletion of your account and information?
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
          <a href="mailto:tanay@tanay.co.in?subject=Newton%20Eyes%20Account%20Deletion%20Request&body=Registered%20Email%20Address%3A%20%5BEnter%20Email%20Address%20Here%5D%0A%0ARequesting%20deletion%20of%20this%20account.%20" style={{
            backgroundColor: '#007bff',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '5px',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}>Yes, proceed with my request.</a>
          <button onClick={onClose} style={{
            padding: '10px 20px',
            fontSize: '16px',
            borderRadius: '5px',
            cursor: 'pointer',
          }}>Cancel</button>
        </div>
      </div>
    </div>
  );
};



function App() {

  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

  useEffect(() => {
    // Parse the query parameters
    const queryParams = new URLSearchParams(window.location.search);
    const page = queryParams.get('page');

    // Check if the page query parameter is 'requestDeletion'
    if (page === 'requestDeletion') {
      setIsDeletionModalOpen(true);
    }
  }, []);

  const handleCloseModal = () => {
    setIsDeletionModalOpen(false);
    //Navigate to the same page remove query params
    window.history.pushState({}, document.title, window.location.pathname);

  };


  // const youtubeVideoId = 'MwLVkwAImT0';
  // const youtubeVideoId = 'NDS3kJhqybk';
  const youtubeVideoId = 'JAlBkE0vCs0';

  const youtubeEmbedUrl = `https://www.youtube.com/embed/${youtubeVideoId}`;

  return (

    <div className="App">
      <DeletionRequestModal isOpen={isDeletionModalOpen} onClose={handleCloseModal} />
      <header className="App-header" style={{


      }}>

        <nav className="App-nav">
          {/* Add navigation links or components here */}
        </nav>
      </header>

      <main>
        <section style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px 0',

          borderBottom: '1px solid #ddd',
          //Keep the items grouped together to the center.
          maxWidth: '1000px',
          margin: '0 auto'




        }}>
          {/* Hero Section */}
          <section className="App-hero">
            <h1
              style={{
                fontSize: '2.5rem',
                fontWeight: 'bold',
                color: 'black'
              }}
            >Welcome to Newton Eyes</h1>
            <p
              style={{
                fontSize: '1.5rem',
                color: 'black'
              }}
            >AI-powered vision companion for the visually challenged.</p>
            <button className="App-hero-button"
              style={{
                padding: '10px 20px',
                fontSize: '1.6rem',
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: '#003C5B',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
              }}
              onClick={() => {
                window.open('https://play.google.com/store/apps/details?id=co.tanay.newtoneyes')
              }}
            >Download Now</button>
          </section>

          <section className="App-hero-image">
            <img src={logoImage} alt="Newton Eyes App"
              style={{
                width: '100%',
                maxWidth: '300px',
                borderRadius: '40%'
              }}
            />
          </section>
        </section>


        {/* App Preview Video Section */}

        <section className="App-video">
          <div className="App-video-container">
            <iframe
              className="App-video-iframe"
              src={youtubeEmbedUrl}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </section>



        {/* Features Section */}
        <section className="App-features">
          <div className="feature-item">
            <img src={featureImageURL1} alt="Identify Objects" className="feature-image" />
            <h3>AI-Driven Descriptions</h3>
            <p>Get detailed, accurate descriptions of photos captured with your camera.</p>
          </div>
          <div className="feature-item">
            <img src={featureImageURL2} alt="Voice Feedback" className="feature-image" />
            <h3>Voice-Powered Interactivity</h3>
            <p>Interact with the app through voice commands and receive audio feedback.</p>
          </div>
          <div className="feature-item">
            <img src={featureImageURL3} alt="Intuitive Interface" className="feature-image" />
            <h3>Multi-Language & Talkback Support</h3>
            Works with accessibility features like Talkback. Supports Indian Languages like Hindi, Telugu, Tamil, Malayalam, Kannada.
          </div>
        </section>

        {/* Empowerment Section */}
        <section className="App-empowerment section">
          <div className="App-empowerment-content">

            <h2>🔍 What Is Newton Eyes?</h2>
            <p>"Newton Eyes" is a cutting-edge app that transforms your smartphone into a powerful visual interpreter. By simply taking a picture, users receive a detailed description of their surroundings, enabling them to perceive the world in ways previously unimaginable. From identifying objects and text to understanding complex environments, "Newton Eyes" acts as a virtual guide, providing voice feedback for every interaction to ensure a seamless and enriching experience.</p>

            <h3>🗣 Voice-Powered Interactivity</h3>
            <p>We believe in the power of voice to simplify interactions. With "Newton Eyes," users can ask questions about their environment using voice input, eliminating the need for typing. Whether it’s inquiring about the color of a shirt or asking for a description of a scene, our app listens and responds with accurate, helpful information. Voice feedback accompanies every action within the app, making it more accessible and convenient for everyone.</p>

            <h2>🌐 Features at a Glance</h2>
            <ul>
              <li><b>AI-Driven Descriptions:</b> Get detailed, accurate descriptions of photos captured with your camera, helping you understand your surroundings better.</li>
              <li><b>Voice Input and Feedback:</b> Interact with the app through voice commands and receive audio feedback, making it fully accessible for visually impaired users.</li>
              <li><b>Intuitive Interface:</b> Designed with accessibility in mind, ensuring ease of use for all users.</li>
            </ul>

            {/* Add a Credits Section */}
            <h2>🙏 Credits</h2>
            <p>Thanks to <b>Hiralal Hadrean, Hansda Haradan of <a href="https://www.jyothiseva.org/about-us/history" target='_blank' rel='noreferrer noopener'
            >Jyothi Seva Home for Blind Children</a></b> (Venkateshpura, Bangalore, India ). They have been instrumental in providing feedback and helping us improve the app to make it more accessible and useful for the visually challenged community. I am grateful for their support and look forward to continuing our collaboration to create a more inclusive world for all.</p>
            <p>Thanks to Sister Clare for the opportunity to work with them on this app.</p>
            <p>
              Thanks to <b>Viswanatha Reddy</b> in helping with ideation, picking the required Generative AI Models, and helping build the app overall.
            </p>
            <p>
              Credit to Thanks to Manish Prasad (Delhi Technical Campus, Greater Noida) for volunteering to help the testing efforts.
            </p>

            <h2>FAQ</h2>
            <h3>
              How to use the app?
            </h3>
            <p>
              On the home screen, click the camera icon in the first half of the page to click a pic. Click the mic icon in the second half of the page to start recording a question. Click the mic icon again to stop recording.
            </p>
            <p>
              In the settings page, you can change the verbosity of the app and enable or disable the "describe pic" feature. You can also toggle if you want to use the camera or the gallery to select an image.
            </p>

            <h3>
              What happens when "Describe Pic" is enabled?
            </h3>
            <p>
              When "Describe Pic" is enabled, the app will describe the image as soon as you select it. You can further then ask follow up questions by clicking and holding the image.
            </p>

            <h3>
              How to change the speaker voice?
            </h3>
            <p>
              The speaker voice can be changed from the settings of your device. <a href="https://support.google.com/accessibility/answer/11221616?hl=en" target="_blank" rel="noreferrer noopener">Here is how you can do it on Android</a>.
            </p>

            <h3>
              What is Verbosity?
            </h3>
            <p>
              Verbosity is the amount of words the app speaks. You can change it using the slider. This applies only to the initial description of the image. Does not apply to follow up questions.
            </p>

            <h3>
              I get a message that the app is unable to reach the servers. What should I do?
            </h3>
            <p>
              Either the app servers are down, or you have been rate limited as you have used the app beyond its fair use limits. Please check back later. If you think you have been rate limited unfairly, please email <a href="mailto:tanay@tanay.co.in?subject=Newton%20Eyes%20App%20feedback" target="_blank" rel="noreferrer noopener">me here</a>.
            </p>

            <h3>
              Is this app free?
            </h3>
            <p>
              Yes, this app is free to use. However, it costs money to run the servers and to use the AI services of cloud providers. I am yet to figure out a way to procure some cloud sponsorship for this app to allow it to be sustainable. I don't intend to monetize the app in any way.
            </p>
            <p>
              If you would like to sponsor the app, please email <a href="mailto:tanay@tanay.co.in?subject=Newton%20Eyes%20App%20sponsorship" >me here</a>.
            </p>

            <h3>
              Who is Newton?
            </h3>
            <p>
              Newton is the name of my Betta fish. He is a great listener and a great friend.
            </p>

            <h3>How to request deletion of your account and info?</h3>
            <p>
              <a href="/?page=requestDeletion">Click here to request deletion of your account and info</a>
            </p>

            <h3>What languages does the app support?</h3>
            <p>
              The app currently supports English, Telugu, Hindi, Tamil, Malayalam, Kannada languages.
            </p>

            <h3>Important Notice:</h3>
            The app is provided as is without any warranty. The developer is not responsible for any damages caused by the app. Do not use the app for taking important decisions. Please rely on your own judgement and usual methods for important decisions.


          </div>
        </section>


      </main >

      <footer className="App-footer">
        {/* Footer content goes here */}
        {/* Small link with label "Click here you request deletion of your account and info". On click should open email with subject prefilled as "Newton Eyes - Account Deletion Request" , and addressed to "tanay@tanay.co.in" */}

        <a style={{
          color: 'white',
          textDecoration: 'none'
        }}
          href="/?page=requestDeletion">Click here to request deletion of your account and info</a>

      </footer>
    </div >
  );
}

export default App;
